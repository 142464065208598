<template>
  <div
    class="d-flex flex-column gap"
    data-cy="search-toolbar"
  >
    <div class="d-flex gap">
      <v-btn
        data-cy="unlock-filter-button"
        v-if="unlock || catsPinned.length || valsPinned.length || hasConds"
        v-model="unlock"
        @click="unlock = !unlock"
        height=32
        icon
        width=32
      >
        <icon-tooltip
          :icon-name="!unlock ? 'i.Lock' : 'i.Unlock'"
          :tooltip-text="!unlock ?$t('t.PinFiltersLocked') : $t('t.PinFiltersUnlocked')"
        />
      </v-btn>
      <responsive-search-field
        v-if="
          useResponsiveSearchField"
        :search="search"
        class="flex-grow-0"
      />
      <filter-search
        :auto-filter-type="search.documentType"
        :target-search="search"
        :visible-cats="visibleCats"
        :cats-pinned.sync="catsPinned"
        :unlock="unlock"
        ref="filterSearch"
      >
        <template v-slot:pins="{item}">
          <pinned-btn
            :is-pinned="isPinned(item)"
            :x-small="unlock"
            :small="!unlock"
            :disabled="isPinDisabled(item)"
            @click="setPins(item)"
          />
        </template>
        <template v-slot:pins-category="{item, isSelected}">
          <pinned-btn
            :is-pinned="isPinned(item)"
            :is-selected="isSelected"
            :x-small="unlock"
            :small="!unlock"
            @click="setPins(item)"
          />
        </template>
      </filter-search>
      <v-btn
        data-cy="clear-search-filters"
        v-if="canResetFilters"
        @click="clearFilters()"
        fab
        x-small
        color="red"
      >
        <icon-tooltip
          icon-name="i.ClearFilter"
          :tooltip-text="$t('t.ClearFilters')"
        />
      </v-btn>
      <v-spacer />
      <v-tooltip
        top
        open-delay="200"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <preset-selector
              type="sort-sets"
              :active-item="search.isSortedByRelevance ? 'relevance' : sortSet"
              :icon="$icon('i.SortSets')"
              :main-search="search"
              :menu-is-open.sync="sortSetIsOpen"
              :prepend-item="{name: $t('t.Relevance'), id: 'relevance', static: true}"
              v-model="sortSet"
            />
          </div>
        </template>
        <span>{{$t('t.SortSets')}}</span>
      </v-tooltip>
      <v-tooltip
        top
        open-delay="200"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <preset-selector
              type="column-sets"
              :active-item="columnSet"
              :icon="$icon('i.ColumnSets')"
              :main-search="search"
              :menu-is-open.sync="columnSetIsOpen"
              v-model="columnSet"
            />
          </div>
        </template>
        <span>{{$t('t.ColumnSets')}}</span>
      </v-tooltip>
      <v-btn
        data-cy="refresh-search"
        color="primary"
        fab
        x-small
        @click="refreshSearch()"
      >
        <icon-tooltip
          icon-name="i.Refresh"
          :tooltip-text="$t('t.Refresh')"
        />
      </v-btn>
      <v-btn
        color="primary"
        fab
        x-small
        @click="showConfirmPopup = true"
      >
        <icon-tooltip
          icon-name="i.MSExcel"
          :tooltip-text="$t('t.Export')"
        />
      </v-btn>
      <v-btn
        color="primary"
        v-if="isSidePanelHidden"
        @click="openSidePanel"
        fab
        x-small
      >
        <icon-tooltip
          :disabled="!isSidePanelHidden"
          icon-name="i.OpenInSidePanel"
          :tooltip-text="$t('t.OpenInSidePanel')"
        />
      </v-btn>
    </div>
    <div
      v-if="isValsPinned"
      class="d-flex align-center gap overflow-y"
    >
      <pinned-vals
        v-model="filters"
        :visible-vals="visibleVals"
        :vals-pinned.sync="valsPinned"
        :target-search="search"
        :stateColumn.sync="stateColumn"
        :unlock="unlock"
      >
        <template v-slot:pins="{item}">
          <pinned-btn
            :is-pinned="isPinned(item)"
            x-small
            @click="setPins(item)"
          />
        </template>
      </pinned-vals>
    </div>
    <div
      v-if="search.filterSet"
      class="d-flex align-center gap overflow-y flex-wrap"
    >
      <pinned-condition
        v-for="(conds, columnId) in groupedConds"
        :key="columnId"
        :conds="conds"
        :search="search"
        :unlock="unlock"
      />
    </div>
    <export
      v-model="showConfirmPopup"
      :search="search"
    />
  </div>
</template>

<script>
import Search from '../controllers'
import sidePanelController from '@/sidePanelController'

export default {
  components: {
    Export: () => import('@/pages/search/components/export'),
    PresetSelector: () => import('@/pages/search/components/preset-selector'),
    ResponsiveSearchField: () => import('@/pages/search/components/responsive-search-field'),
    FilterSearch: () => import('@/pages/search/components/filter/filter-search'),
    PinnedBtn: () => import('@/pages/search/components/filter/pinned-btn'),
    PinnedCondition: () => import('@/pages/search/components/filter/pinned-condition'),
    PinnedVals: () => import('@/pages/search/components/filter/pinned-vals'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    visibleCats () {
      return this.search.visiblePins?.cats?.map(c => ({ id: c, type: 'auto-filters' }))
    },
    visibleVals () {
      return this.search.visiblePins?.vals
    },
    valsPinned: {
      get () {
        return this.search.pins?.vals ?? []
      },
      set (v) {
        this.search.pins.setVals(v)
        this.applyPins()
      }
    },
    canResetFilters () {
      return this.search.filterSet?.hasActiveFilter
    },
    catsPinned: {
      get () {
        return this.search.pins?.cats ?? []
      },
      set (v) {
        this.search.pins.setCats(v)
        this.applyPins()
      }
    },
    isValsPinned () {
      return this.visibleVals?.length || (this.search.pins?.vals.length && this.unlock)
    },
    columnSet: {
      get () {
        return this.search.columnSet
      },
      set (item) {
        this.columnSetIsOpen = false
        if (item.id !== this.search.columnSet) {
          this.search.setColumnSet(item.id).execute()
        }
      }
    },
    filters: {
      get () {
        return this.search.filterSet
      },
      set (filterSet) {
        this.search.setFilterSet(filterSet).execute()
      }
    },
    groupedConds () {
      return this.search.filterSet?.groupedConds ?? {}
    },
    hasConds () {
      return Object.keys(this.groupedConds).length
    },
    sortSet: {
      get () {
        return this.search.sortSet
      },
      set (item) {
        this.sortSetIsOpen = false
        if (item.id !== this.search.sortSet || this.search.sorts.sortBy.length) {
          this.search.setSortSet(item.static ? undefined : item.id).execute()
        }
      }
    },
    stateColumn: {
      get () {
        return this.search.pins.stateColumn
      },
      set (stateColumn) {
        this.search.pins.setStateColumn(stateColumn)
        this.applyPins()
      }
    },
    isSidePanelHidden () {
      return sidePanelController.displayedDocument && sidePanelController.isSidePanelHidden
    }
  },
  data () {
    return {
      columnSetIsOpen: false,
      sortSetIsOpen: false,
      unlock: false,
      showConfirmPopup: false
    }
  },
  methods: {
    applyPins () {
      this.search.setPins(this.search.pins).execute()
    },
    clearFilters () {
      this.search.filterSet.clearAllConditionValues()
      this.$refs.filterSearch?.clearSelection()
    },
    isPinned (item) {
      return this.search.pins?.isPinned(item) ?? false
    },
    setPins (item) {
      this.search.pins.setPins(item)
      this.applyPins()
    },
    isPinDisabled (item) {
      if (item.type === 'auto-filters') {
        return this.catsPinned?.length >= 10
      } else {
        return this.valsPinned?.length >= 10
      }
    },
    openSidePanel () {
      sidePanelController.toggle()
    },
    refreshSearch () {
      this.search.execute()
    }
  },
  watch: {
    'search.documentType' () {
      this.unlock = false
    }
  },
  props: {
    useResponsiveSearchField: {
      default: false,
      type: Boolean
    },
    search: Search
  }
}
</script>

<style lang="stylus" scoped>
.gap
  gap 16px
</style>
